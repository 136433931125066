/* partners */
.partners_wrap .content_title .heading1 {
  top: 72%;
}

.partners_wrap .content_bg {
  padding: 50px 0;
}

.partners_box {
  max-width: 1280px;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(165, 169, 188, 0.5);
  border-radius: 20px;
}

.partners_cont {
  max-width: 1200px;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.partners_cont img {
  max-width: 100%;
}

.partners_wrap .route li:nth-child(2)::after {
  display: none;
}

.voucher_wrap .route ul li:nth-child(2)::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #d2d4de;
  border-right: 2px solid #d2d4de;
  transform: rotate(45deg);
}

/* casebook */
.casebook_page .content_bg {
  padding: 50px 0;
}

.casebook_page .content_title h2 {
  font-size: 2rem;
  font-weight: 400 !important;
  text-align: center;
  width: 100%;
  padding: 0 20px;
}

.casebook_wrap {
  display: flex;
  justify-content: space-between;
}

.casebook_wrap .casebook_left {
  width: 620px;
  height: 860px;
}

.casebook_left {
  background-color: #fff;
  border: 1px solid rgb(218, 220, 224);
  border-radius: 8px;
  padding: 22px 24px 24px;
}

.casebook_left .left_img {
  width: 570px;
  margin-bottom: 20px;
}

.casebook_left .left_img img {
  width: 100%;
}

.casebook_wrap iframe {
  width: 620px;
  height: 860px;
  border: 1px solid rgb(218, 220, 224);
  border-radius: 8px;
  padding-top: 10px;
  background-color: #f0ebf8;
}

@media screen and (max-width: 1280px) {
  .casebook_wrap {
    flex-direction: column;
    align-items: center;
  }

  .casebook_wrap .casebook_left {
    height: 100%;
  }

  .casebook_wrap iframe {
    margin-top: 30px;
  }

  .slogan_img_wrap {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .casebook_wrap .casebook_left,
  .casebook_wrap iframe,
  .casebook_left .left_img {
    width: 100%;
  }
}
